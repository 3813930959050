import '@fontsource/khula'
import '@fontsource/oswald'
import './src/styles/global.css'

import AOS from 'aos/dist/aos'
import 'aos/dist/aos.css'

AOS.init({
  offset: 200,
  duration: 600,
  easing: 'ease-in-sine',
  delay: 100,
})
